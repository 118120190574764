import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../components/seo'
import NewsSectionBlock from '../components/NewsSectionBlock'
import Typography from '../components/Common/Typography'
import Link from '../components/Common/Link'

import * as s from '../pages/news.module.scss'

const BdtPage: React.FC = () => {
  return (
    <>
      <SEO
        title="BDT"
        description="Join us at the Blockchain Development & Technology 2022 conference! Explore the latest trends, innovations, and use cases in blockchain technology."
      />
      <NewsSectionBlock date="06.09.2022">
        <Typography variant="h1" color="blue">
          {`SIGNAX Will Participate in BIM & Digital Twin\n Middle East 2022 Forum`}
        </Typography>
        <div className={s.imageWrap}>
          <div className="flex">
            <StaticImage
              width={580}
              imgClassName="p-2"
              src="../assets/images/news/bdt-2022/image-1.png"
              alt="BIM & Digital Twin Middle East 2022 Forum"
              title="BIM & Digital Twin Middle East 2022 Forum"
              placeholder="blurred"
            />
            <StaticImage
              width={580}
              imgClassName="p-2"
              src="../assets/images/news/bdt-2022/image-2.png"
              alt="BIM & Digital Twin Middle East 2022 Forum 2"
              title="BIM & Digital Twin Middle East 2022 Forum 2"
              placeholder="blurred"
            />
          </div>
        </div>
        <Typography variant="body2">
          <Link
            href="https://www.itp.events/BIMDigitalTwinsMiddleEast2022"
            rel="nofollow noreferrer"
            target="_blank"
          >
            BIM & Digital Twin Middle East 2022 Forum
          </Link>{' '}
          will take place in Dubai, September 20-21. SIGNAX is preparing for
          active participation in it.
        </Typography>
        <Typography variant="body1">
          BIM & Digital Twin Forum is the region’s leading and biggest event on
          Building Information Modelling (BIM), Digital Twins and related
          technology. Forum attracts all stakeholders in AEC including
          government; municipalities; contractors; infrastructure operators;
          civil engineering; developers; architecture; consultants; technology
          providers and industry associations. Autodesk, Trimble and Building
          Smart are among the participants.
        </Typography>
        <Typography variant="body1">
          SIGNAX acts a sponsor and exhibitor at the Forum. The presentation
          will be devoted to the power of BIM usage on the construction site.
          Nowadays, the designers are ready to give 3D model to the next stage.
          And it’s a big challenge for the developers and contractors to get a
          maximum value using digital data in field. Let’s make this important
          step together!
        </Typography>
        <Typography variant="body1">
          SIGNAX team invites you to visit our stand where you would see the
          interactive demo of SIGNAX modules. You will have an opportunity to
          track all the stages of BIM workflows and test how you can control the
          construction using just smartphone or tablet. We are also preparing
          technology surprises for you. Welcome to our stand and look how
          innovations can work on construction site!
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/news/bdt-2022/preview.png"
            alt="BIM & Digital Twin Middle East 2022 Forum 3"
            title="BIM & Digital Twin Middle East 2022 Forum 3"
            placeholder="blurred"
          />
        </div>
      </NewsSectionBlock>
    </>
  )
}

export default BdtPage
